import { createHead } from '@unhead/vue'

export const head = createHead()

export function setupDefaultMeta() {
  head.push({
    meta: [
      {
        name: "robots",
        content: "noindex,nofollow"
      },
      {
        name: "pinterest",
        content: "nopin"
      }
    ]
  })
}
