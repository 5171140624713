import { createI18n } from 'vue-i18n'
import { nextTick } from 'vue'
import en from "./locales/en.json"

export async function loadLocaleMessages(i18n:any, locale: string) {
  const messages = await import(
    `./locales/${locale}.json`
  )

  i18n.setLocaleMessage(locale, messages.default)
  setI18nLanguage(i18n, locale)

  return nextTick()
}

export function setI18nLanguage(i18n:any, locale: string) {
  i18n.locale.value = locale
  document.querySelector('html')!.setAttribute('lang', locale)
}

export function setupI18n(options = { locale: "en" }) {
  const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: options.locale,
    messages: {en: en}
  })

  // loadLocaleMessages(i18n, "en")

  return i18n
}
