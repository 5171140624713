<script setup lang="ts">
import { handleNotification } from '@/composables/Notifications'
import { usePlatformStore } from '@/stores/Platform'
import { onMounted, onUnmounted, watch } from 'vue'
import { EventSource } from 'extended-eventsource'
import { useRouter, useRoute } from "vue-router"
import Layout from '@/components/Layout.vue'
import { useUserStore } from '@/stores/User'
import { storeToRefs } from "pinia"

const router = useRouter()
const route = useRoute()

const platformStore = usePlatformStore()
const userStore = useUserStore()
const { codename, version } = storeToRefs(platformStore)
const { signedIn } = storeToRefs(userStore)

console.log(`💾 Submit SPA: "${codename.value}" v${version.value}`)
console.log(`🐶 For Pennie`)

let retryCount = 0
const maxRetries = 100
let eventSource: EventSource
let retryInterval = 10000
let retryTimeout: any

function startEventSource() {
  if (retryCount >= maxRetries) {
    console.log('Submit RTN: Max connection retries reached, stopping.')
    return
  }

  eventSource = new EventSource(import.meta.env.VITE_NOTIFICATIONS_API, {
    withCredentials: true
  })

  eventSource.onerror = function(err) {
    console.error('EventSource failed:', err)
    eventSource.close()
    platformStore.setRTN(false)
    retryCount++
    // Increase retry interval every 25 attempts
    if (retryCount % 25 === 0) {
      retryInterval *= 2
    }
    if (!document.hidden) {
      retryTimeout = setTimeout(startEventSource, retryInterval);
    }
  }

  eventSource.onopen = function() {
    // Reset retry count and interval on successful connection
    retryCount = 0
    retryInterval = 10000
    console.log('Submit RTN: Connection opened.')
    platformStore.setRTN(true)
  }

  eventSource.onmessage = function(event) {
    handleNotification(platformStore, userStore, event.data)
  }
}

async function handleVisibilityChange() {
  if (document.hidden) {
    // Page is not visible, close the connection
    if (eventSource && eventSource.readyState !== 2) {
      console.log('Submit RTN: Page is hidden, closing connection.')
      eventSource.close()
      platformStore.setRTN(false)
      clearTimeout(retryTimeout)
    }
  } else {
    try {
      await userStore.initUser()
      await platformStore.checkForNewVersion()
    } catch (error) {
      await userStore.signOut()
      return Promise.reject(await router.push({
        name: "SignIn",
        query: { redirect: route.fullPath }
      }))
    }
    // Page is visible, start the connection
    console.log('Submit RTN: Page is visible, starting connection.')
    startEventSource()
  }
}

async function handleFocusChange() {
  if (document.hasFocus()) {
    try {
      await userStore.initUser()
    } catch (error) {
      await userStore.signOut()
      return Promise.reject(await router.push({
        name: "SignIn",
        query: { redirect: route.fullPath }
      }))
    }
  }
}

onMounted(() => {
  if (signedIn.value) {
    if (document.hidden) {
      document.addEventListener('visibilitychange', handleVisibilityChange)
      document.addEventListener('focus', handleFocusChange)
    } else {
      startEventSource()
      document.addEventListener('visibilitychange', handleVisibilityChange)
      document.addEventListener('focus', handleFocusChange)
    }
  }
})

onUnmounted(() => {
  // Clean up when the component is unmounted
  if (eventSource && eventSource.readyState !== 2) {
    eventSource.close()
    platformStore.setRTN(false)
  }
  clearTimeout(retryTimeout)
  document.removeEventListener('visibilitychange', handleVisibilityChange)
})

// watch for changes to the signedIn state
watch(signedIn, (newVal) => {
  if (newVal) {
    if (document.hidden) {
      document.addEventListener('visibilitychange', handleVisibilityChange)
    } else {
      startEventSource()
      document.addEventListener('visibilitychange', handleVisibilityChange)
    }
  } else {
    if (eventSource && eventSource.readyState !== 2) {
      eventSource.close()
      platformStore.setRTN(false)
    }
    clearTimeout(retryTimeout)
    document.removeEventListener('visibilitychange', handleVisibilityChange)
  }
})

const addMaximumScaleToMetaViewport = () => {
  const el = document.querySelector('meta[name=viewport]')

  if (el !== null) {
    let content = el.getAttribute('content') as string
    let re = /maximum\-scale=[0-9\.]+/g

    if (re.test(content)) {
        content = content.replace(re, 'maximum-scale=1.0')
    } else {
        content = [content, 'maximum-scale=1.0'].join(', ')
    }

    el.setAttribute('content', content)
  }
}

const disableIosTextFieldZoom = addMaximumScaleToMetaViewport

const checkIsIOS = () =>
  /iPad|iPhone|iPod/.test(navigator.userAgent)

if (checkIsIOS()) {
  disableIosTextFieldZoom()
}
</script>

<template>
  <Layout>
    <RouterView />
  </Layout>
</template>
